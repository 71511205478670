/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------

==========================================================================================*/


export default [
  {
    url: '/',
    slug: 'dashbord',
    icon: 'HomeIcon',
    i18n: 'dashboard',
    showMobile: true
  },
  {
    url: '/sales/calendar',
    slug: 'saleCalendar',
    icon: 'CALENDER',
    featherIcon: false,
    i18n: 'sales.invoices.calendar.title',
    permission: 'calendar.show',
    showMobile: true
  },

  {
    url: "/customers",
    slug: 'customers',
    icon: 'UsersIcon',
    i18n: 'users.customers.title',
    permission: ['user.show', 'character.show', 'role.show'],
    showMobile: true,
    submenu: [
      {
        url: '/customers',
        slug: 'customers',
        i18n: 'users.customers.list.title',
        permission: 'user.show',
        showMobile: true
      },
      {
        url: "/characters",
        slug: 'characters',
        i18n: 'characters.title',
        permission: 'character.show',
        showMobile: true
      },
      {
        url: '/settings/access',
        slug: 'accessSetting',
        i18n: 'setting.access.title',
        permission: 'role.show',
        showMobile: true
      }
    ]
  },
  {
    url: '',
    slug: 'sale',
    icon: 'SALE',
    featherIcon: false,
    i18n: 'sales.title',
    permission: ['invoice.show', 'sale_addon.show', 'discount_group.show'],
    showMobile: true,
    submenu:
      [
        {
          url: "/sales/invoices",
          slug: 'saleInvoices',
          i18n: 'sales.invoices.title',
          permission: 'invoice.show',
          showMobile: true
        },
        {
          url: '/sales-addons',
          slug: 'salesAddons',
          i18n: 'sales.addons.title',
          permission: 'sale_addon.show',
          showMobile: true
        },
        {
          url: '/discount-groups',
          slug: 'discountGroups',
          i18n: 'sales.discount.title',
          permission: 'discount_group.show',
          showMobile: true
        },
      ]
  },
  {
    url: '',
    slug: 'academy',
    icon: 'ACADEMY',
    featherIcon: false,
    i18n: 'academy.title',
    permission: ['coach.show'],
    showMobile: true,
    submenu:
      [
        {
          url: "/coaches",
          slug: 'coaches',
          i18n: 'coaches.title',
          showMobile: true,
          permission: 'coach.show'
        }
      ]
  },

  {
    url: null,
    slug: 'treasury',
    icon: 'DollarSignIcon',
    i18n: 'treasury.title',
    permission: 'treasury.show',
    showMobile: true,
    submenu:
      [
        {
          url: '/treasury/banks',
          slug: 'banks',
          i18n: 'treasury.banks.title',
          permission: 'bank.show',
          showMobile: true
        },
        {
          url: '/treasury/payment-gateways',
          slug: 'banks',
          icon: 'TENNIS_COURT',
          featherIcon: false,
          i18n: 'treasury.paymentGateways.title',
          permission: 'payment_gateway.show',
          showMobile: true
        },
        {
          url: '/treasury/cash_boxes',
          slug: 'cash',
          i18n: 'treasury.cashBoxes.title',
          permission: 'cash.show',
          showMobile: true
        },
        {
          url: '/treasury/internal-funds-transfers',
          slug: 'internal-funds-transfers',
          i18n: 'treasury.internalFundsTransfer.title',
          permission: 'internal_fund.show',
          showMobile: true
        },
        {
          url: '/treasury/receive',
          slug: 'receive',
          i18n: 'treasury.receive.title',
          permission: 'receive.show',
          showMobile: true
        },
        {
          url: '/treasury/payment',
          slug: 'payment',
          i18n: 'treasury.payment.title',
          permission: ['payment.show'],
          showMobile: true
        }
      ]
  },

  {
    url: null,
    slug: 'accountancy',
    icon: 'LayersIcon',
    i18n: 'accountancy.title',
    permission: ['opening.access', 'document.access', 'account.access'],
    showMobile: true,
    submenu:
      [
        {
          url: '/accountancy/opening-balance',
          slug: 'opening-balance',
          permission: 'opening.access',
          i18n: 'accountancy.openingBalance.title',
          showMobile: true
        },
        {
          url: '/accountancy/documents',
          slug: 'documents',
          permission: 'document.access',
          i18n: 'accountancy.documents.title',
          showMobile: true
        },
        {
          url: '/accountancy/types',
          slug: 'types',
          permission: 'account.access',
          i18n: 'accountancy.types.title',
          showMobile: true
        },
        {
          url: '/treasury/cost',
          slug: 'cost',
          i18n: 'treasury.cost.title',
          permission: ['cost.show'],
          showMobile: true
        },

      ]
  },

  {
    url: '',
    slug: 'settings',
    i18n: 'club.setting.title',
    icon: 'SettingsIcon',
    permission: 'club_setting.show',
    showMobile: true,
    submenu:
      [
        {
          url: "/times",
          slug: 'times',
          i18n: 'times.title',
          permission: 'time.show',
          showMobile: true
        },
        {
          url: "/terms",
          slug: 'terms',
          i18n: 'terms.title',
          permission: 'term.show',
          showMobile: true
        },
        {
          url: "/courts",
          slug: 'courts',
          i18n: 'courts.title',
          permission: 'court.show',
          showMobile: true
        },
        {
          url: "/refunds",
          slug: 'refunds',
          i18n: 'refunds.title',
          showMobile: true,
          permission: 'refund_rule.show'
        },
        {
          url: '/closed-invoice',
          slug: 'closedInvoices',
          i18n: 'closed.title',
          permission: 'closed_invoice.show',
          showMobile: true
        },
      ]
  },
  {
    url: '',
    slug: 'reports',
    i18n: 'report.title',
    icon: 'PieChartIcon',
    permission: 'report.show',
    showMobile: true,
    submenu:
      [
        // {
        //   url: '/reports/user-report',
        //   slug: 'userReport',
        //   i18n: 'report.userReport.title',
        //   permission: 'event.show'
        // },
        {
          url: '/reports/sale-details',
          slug: 'saleDetailReport',
          i18n: 'report.saleDetail.title',
          permission: 'report.show',
          showMobile: true
        },
        {
          url: '/reports/sale-addon-details',
          slug: 'saleAddonDetailReport',
          i18n: 'report.saleAddonDetail.title',
          permission: 'report.show',
          showMobile: true
        },
        {
          url: '/reports/coaches',
          slug: 'coachReport',
          i18n: 'report.coach.title',
          permission: 'report.show',
          showMobile: true
        }
      ]
  },
  {
    url: '',
    slug: 'tickets',
    i18n: 'tickets.title',
    icon: 'SEND',
    featherIcon: false,
    permission: ['support_ticket.show', 'organizational_ticket.show'],
    permissionType: 'all',
    showMobile: true,
    submenu:
      [
        {
          url: '/tickets/support',
          slug: 'supportTickets',
          i18n: 'tickets.support.title',
          permission: 'support_ticket.show',
          showMobile: true
        },
        {
          url: '/tickets/organization',
          slug: 'organizationTickets',
          i18n: 'tickets.organization.title',
          permission: 'organizational_ticket.show',
          showMobile: true
        }
      ]
  },
  {
    url: '/tickets/support',
    slug: 'supportTickets',
    icon: 'SEND',
    featherIcon: false,
    i18n: 'tickets.support.title',
    permission: ['support_ticket.show', '!organizational_ticket.show'],
    permissionType: 'all',
    showMobile: true,
  },
  {
    url: '/tickets/organization',
    slug: 'organizationTickets',
    icon: 'SEND',
    featherIcon: false,
    i18n: 'tickets.organization.title',
    permission: ['!support_ticket.show', 'organizational_ticket.show'],
    permissionType: 'all',
    showMobile: true,
  },
  {
    url: '/events',
    slug: 'events',
    icon: 'MessageCircleIcon',
    i18n: 'events.title',
    permission: ['event.show'],
    showMobile: true,
  },
  {
    url: '/settings',
    slug: 'settings',
    i18n: 'setting.title',
    icon: 'SettingsIcon',
    permission: ['setting.show'],
    showMobile: true
  },
  {
    url: '/backup',
    slug: 'backup',
    i18n: 'backup.title',
    icon: 'DatabaseIcon',
    permission: 'backup.show',
    showMobile: true
  },
]
